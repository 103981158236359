import { useState, useEffect } from 'react';

import { Box, Fade, Paper, Typography } from '@mui/material';

import { localStorageGetItem, localStorageSetItem, localStorageRemoveItem } from 'src/utils/local-storage';

import { useTranslate } from 'src/locales';

import Iconify from '../iconify';

const STORAGE_KEY = 'kanban-scroll-hint-count';
const MAX_SHOWS = 3;

const ScrollHint = () => {

  const { t } = useTranslate();

  const [show, setShow] = useState(false);

  useEffect(() => {

    let timer: NodeJS.Timeout;
    const showCount = parseInt(localStorageGetItem(STORAGE_KEY) || '0', 10);
    if (showCount < MAX_SHOWS) {
      setShow(true);

      // Increment and save the counter
      localStorageSetItem(STORAGE_KEY, (showCount + 1).toString());

      // Hide after 5 seconds
      timer = setTimeout(() => {
        setShow(false);
      }, 5000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, []);

  return (
    <Fade in={show} timeout={1000}>
      <Paper
        elevation={3}
        sx={{
          position: 'fixed',
          bottom: 32,
          left: '50%',
          transform: 'translateX(-50%)',
          bgcolor: 'rgba(0, 0, 0, 0.8)',
          color: 'common.white',
          px: 2,
          py: 1,
          borderRadius: 2,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          zIndex: 1300
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Iconify icon="ix:mouse-click-filled" className="w-4 h-4" color="white" />
          <Typography variant="body2">
            {t('kanban.scroll_hint')}
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 0.5,
            color: 'grey.400'
          }}
        >
          <Typography variant="body2" sx={{ fontWeight: 500 }}>
            {t('kanban.shift')}
          </Typography>
          <Typography variant="body2">+</Typography>
          <Iconify icon="hugeicons:mouse-scroll-01" className="w-4 h-4" />
        </Box>
      </Paper>
    </Fade>
  );
};

ScrollHint.resetShowCount = () => {
  localStorageRemoveItem(STORAGE_KEY);
};

export default ScrollHint;