// ----------------------------------------------------------------------

const STORAGE_PREFIX = 'jt_storage_';

export function localStorageAvailable() {
  try {
    const key = '__some_random_key_you_are_not_going_to_use__';
    localStorage.setItem(`${STORAGE_PREFIX}${key}`, key);
    localStorage.removeItem(`${STORAGE_PREFIX}${key}`);
    return true;
  } catch (error) {
    return false;
  }
}

export function localStorageSetItem(key: string, value: any) {
  const storageAvailable = localStorageAvailable();

  if (storageAvailable) {
    localStorage.setItem(`${STORAGE_PREFIX}${key}`, value);
  }
}

export function localStorageRemoveItem(key: string) {
  localStorage.removeItem(`${STORAGE_PREFIX}${key}`);
}

export function localStorageGetItem(key: string, defaultValue = '') {
  const storageAvailable = localStorageAvailable();

  let value;

  if (storageAvailable) {
    value = localStorage.getItem(`${STORAGE_PREFIX}${key}`) || defaultValue;
  }

  return value;
}
