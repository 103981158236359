import { t } from 'i18next';

import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import { useTheme } from '@mui/material/styles';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import { useOffSetTop } from 'src/hooks/use-off-set-top';

import { bgBlur } from 'src/theme/css';
import { useAuth } from 'src/auth/hooks/useAuth';
import AccountPopover from 'src/layouts/common/account-popover';

import Logo from 'src/components/logo';

import { HEADER } from '../config-layout';
import HeaderShadow from '../common/header-shadow';

// ----------------------------------------------------------------------

export default function ExternalHeader() {
  const theme = useTheme();

  const offsetTop = useOffSetTop(HEADER.H_DESKTOP);

  const { isAuthenticated } = useAuth();

  const router = useRouter();

  const navigate = (route: string) => {
    router.push(route);
  }

  return (
    <AppBar>
      <Toolbar
        sx={{
          justifyContent: 'space-between',
          height: {
            xs: HEADER.H_MOBILE,
            md: HEADER.H_DESKTOP,
          },
          transition: theme.transitions.create(['height'], {
            easing: theme.transitions.easing.easeInOut,
            duration: theme.transitions.duration.shorter,
          }),
          ...(offsetTop && {
            ...bgBlur({
              color: theme.palette.background.default,
            }),
            height: {
              md: HEADER.H_DESKTOP_OFFSET,
            },
          }),
        }}
      >
        <Logo />

        <Stack direction="row" alignItems="center" spacing={1}>

          {
            isAuthenticated ? (
              <>
                <Button variant='text' onClick={() => navigate(paths.dashboard.root)}>{t('common.dashboard')}</Button>
                <AccountPopover />
              </>
            ) : (
              <>
                <Button variant="contained" onClick={() => navigate(paths.auth.login)}>{t('auth.utils.login')}</Button>
                <Button variant='text' onClick={() => navigate(paths.auth.register.root)}>{t('auth.utils.sign-up')}</Button>
              </>
            )
          }

        </Stack>
      </Toolbar>

      <HeaderShadow />
    </AppBar>
  );
}
