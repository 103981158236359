import Markdown from 'react-markdown';

import { Dialog, Button, DialogTitle, DialogContent, DialogActions } from '@mui/material';

import { useTranslate } from 'src/locales';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  terms?: string;
};

export default function TermsModal({ terms, open, onClose }: Props) {
  const { t } = useTranslate();

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{t('auth.register.terms_modal.title')}</DialogTitle>
      <DialogContent>
        <Markdown children={terms} />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('common.close')}</Button>
      </DialogActions>
    </Dialog>
  );
}
