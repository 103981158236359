import { t } from 'i18next';
import DOMPurify from 'dompurify';
import React, { useRef, useState, useEffect } from 'react';

import { Button } from '@mui/material';

interface TruncatedHTMLRendererProps {
  html: string;
  mode: 'lines' | 'characters';
  limit: number;
  expandable?: boolean;
  readMoreText?: string;
  readLessText?: string;
  onExpandChange?: (isExpanded: boolean) => void;
  style?: React.CSSProperties;
}

const TruncatedHTMLRenderer: React.FC<TruncatedHTMLRendererProps> = ({
  html,
  mode,
  limit,
  expandable = true,
  readMoreText = t('common.read_more'),
  readLessText = t('common.read_less'),
  onExpandChange,
  style,
}) => {
  const [truncated, setTruncated] = useState<string>('');
  const [isTruncated, setIsTruncated] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const fullContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const cleanHtml = DOMPurify.sanitize(html);
    setIsExpanded(false);

    if (mode === 'characters') {
      if (cleanHtml.length > limit) {
        setTruncated(`${cleanHtml.slice(0, limit)}...`);
        setIsTruncated(true);
      } else {
        setTruncated(cleanHtml);
        setIsTruncated(false);
      }
    } else if (mode === 'lines') {
      // Create a temporary element to measure the full content
      const tempElement = document.createElement('div');
      tempElement.innerHTML = cleanHtml;
      tempElement.style.visibility = 'hidden';
      tempElement.style.position = 'absolute';
      tempElement.style.width = contentRef.current ? `${contentRef.current.clientWidth}px` : '100%';
      document.body.appendChild(tempElement);

      const lineHeight = parseInt(window.getComputedStyle(tempElement).lineHeight, 10)
      const maxHeight = (lineHeight + 5) * limit;

      if (tempElement.offsetHeight > maxHeight) {
        // Content needs truncation
        let low = 0;
        let high = cleanHtml.length;
        let mid;
        let result = cleanHtml;

        while (low <= high) {
          mid = Math.floor((low + high) / 2);
          tempElement.innerHTML = `${cleanHtml.slice(0, mid)}...`;

          if (tempElement.offsetHeight > maxHeight) {
            high = mid - 1;
          } else {
            result = `${cleanHtml.slice(0, mid)}...`;
            low = mid + 1;
          }
        }
        setTruncated(result);
        setIsTruncated(true);
      } else {
        setTruncated(cleanHtml);
        setIsTruncated(false);
      }

      document.body.removeChild(tempElement);
    }
  }, [html, mode, limit]);

  const toggleExpand = (): void => {
    setIsExpanded(!isExpanded);
    onExpandChange?.(!isExpanded);
  };

  return (
    <div className="truncated-html-renderer">
      <div
        ref={contentRef}
        style={{
          overflow: 'hidden',
          maxHeight: mode === 'lines' && !isExpanded ? `${limit * parseInt(window.getComputedStyle(document.body).lineHeight, 10)}px` : 'none',
          wordBreak: 'break-word',
          ...style
        }}
        dangerouslySetInnerHTML={{
          __html: isExpanded ? DOMPurify.sanitize(html) : truncated,
        }}
      />

      {(isTruncated && expandable) && (
        <div style={{ marginTop: '5px' }}>
          <Button variant='text' onClick={toggleExpand} sx={{ color: 'text.disabled' }}>
            {isExpanded ? readLessText : readMoreText}
          </Button>
        </div>
      )}
    </div>
  );
};

export default TruncatedHTMLRenderer;