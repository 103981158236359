export enum FeatureType {
  Connect_Recruiters = 'connect_recruiters',
  Free_ConnectRecruiters = 'free_recruiter_connections_enabled',
  Connect_Clients = 'connect_clients',
  Connect_Candidates = 'connect_candidates',
  ToB_Enabled = 'connection_tob_enabled',
  Live_Alerts = 'live_activity_alerts',
  Past_Candidate_Updates = 'past_candidate_updates_enabled',
  Operation_Roles = 'operational_roles_enabled',
  Create_Departments = 'create_departments',
  Team_Seats = 'team_seat',
  Create_Jobs = 'create_jobs',
  Manage_Jobs = 'manage_internal_jobs',
  Integrations = 'integrations_enabled',
  Internal_Jobs_Enabled = 'manage_internal_jobs',
  Interview_Scheduling = 'interview_scheduling',
  Job_Invites = 'job_invites',
  Sub_Organization_Enabled = 'sub_organization_enabled',
  Sub_Organization_Team_Seat = 'sub_organization_team_seat'
}
