import { Box } from '@mui/system';

import ExternalHeader from './header';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function ExternalLayout({ children }: Props) {
  return (
    <>
      <ExternalHeader />

      <Box
        sx={{
          py: 12,
          width: '100%',
          minHeight: '100vh',
          textAlign: 'center'
        }}
      >
        {children}
      </Box>
    </>
  );
}
