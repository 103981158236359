import { useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';

Sentry.init({
  enabled: ['prod', 'staging'].includes(import.meta.env.VITE_NODE_ENV),
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `jt-client@${import.meta.env.VITE_APP_VERSION}`,
  environment: import.meta.env.VITE_NODE_ENV,
  integrations: [
    // See docs for support of different versions of variation of react router
    // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for tracing.
  tracesSampleRate: 1.0,

  // Set `tracePropagationTargets` to control for which URLs trace propagation should be enabled
  tracePropagationTargets: [/^\//, /^https:\/\/api.jobtetris\.com/],

  // Capture Replay for 10% of all sessions,
  // plus for 100% of sessions with an error
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  normalizeDepth: 10,

  ignoreErrors: [
    // Add patterns for errors you want to ignore
    'ResizeObserver loop limit exceeded',
    /Chrome Extensions/,
  ],

  beforeSend(event, hint) {
    if (hint.originalException === "Timeout") return null;
    return event;
  }
});
