import { lazy, Suspense } from 'react';
import { ErrorBoundary } from '@sentry/react';

import ExternalLayout from 'src/layouts/external';
import SentryErrorPage from 'src/pages/SentryErrorPage';
import AuthModernCompactLayout from 'src/layouts/auth/modern-compact';

import { SplashScreen } from 'src/components/loading-screen';

const ExperienceVerificationPage = lazy(
  () => import('src/pages/external/experience-verification-page')
);

const JobBoardPage = lazy(
  () => import('src/pages/external/job-boards/job-board-page')
);

export const externalRoutes = [
  {
    path: 'experience-verification/:request_id',
    element: (
      <ErrorBoundary fallback={<SentryErrorPage />}>
        <Suspense fallback={<SplashScreen />}>
          <AuthModernCompactLayout>
            <ExperienceVerificationPage />
          </AuthModernCompactLayout>
        </Suspense>
      </ErrorBoundary>
    ),
  },
  {
    path: 'job-boards/:board_id/jobs',
    element: (
      <ErrorBoundary fallback={<SentryErrorPage />}>
        <Suspense fallback={<SplashScreen />}>
          <ExternalLayout>
            <JobBoardPage />
          </ExternalLayout>
        </Suspense>
      </ErrorBoundary>
    ),
  }
];
