import { m } from 'framer-motion';
import { useCallback } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';

import i18n from 'src/locales/i18n';
import { logOut } from 'src/auth/utils';
import { useTranslate } from 'src/locales';
import { rootApi } from 'src/services/rootApi';
import { useAppDispatch } from 'src/store/store';
import { useAuth } from 'src/auth/hooks/useAuth';
import { useGetMyAccountProfileQuery } from 'src/services/account/account.service';

import { varHover } from 'src/components/animate';
import { useSettingsContext } from 'src/components/settings';
import CustomPopover, { usePopover } from 'src/components/custom-popover';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    label: i18n.t('navigationPopover.home'),
    linkTo: '/',
  },
  {
    label: i18n.t('navigationPopover.account'),
    linkTo: paths.dashboard.account.root,
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const router = useRouter();

  const settings = useSettingsContext();

  const { context, isAuthenticated } = useAuth();
  const { currentData: profileData } = useGetMyAccountProfileQuery();

  const popover = usePopover();

  const dispatch = useAppDispatch();

  const { t } = useTranslate();

  const handleLogout = useCallback(async () => {
    try {
      popover.onClose();
      router.replace(paths.auth.login);
      logOut();
      dispatch(rootApi.util.resetApiState());
    } catch (error) {
      console.error(error);
    }
  }, [popover, router, dispatch]);

  const handleClickItem = useCallback((path: string) => {
    popover.onClose();
    router.push(path);
  }, [popover, router]);

  const handleOpenLayoutSettings = useCallback(() => {
    popover.onClose();
    settings.onToggle();
  }, [settings, popover]);

  return (
    <>
      {
        isAuthenticated &&
        (
          <>
            <IconButton
              component={m.button}
              whileTap="tap"
              whileHover="hover"
              variants={varHover(1.05)}
              onClick={popover.onOpen}
              id="account_popover"
              sx={{
                width: 40,
                height: 40,
                background: (theme) => alpha(theme.palette.grey[500], 0.08),
                ...(popover.open && {
                  background: (theme) =>
                    `linear-gradient(135deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 100%)`,
                }),
              }}
            >
              <Avatar
                src={profileData?.profile_image?.public_path}
                alt={context?.user?.name}
                sx={{
                  width: 36,
                  height: 36,
                  border: (theme) => `solid 2px ${theme.palette.background.default}`,
                }}
              >
                {context?.user?.name?.charAt(0).toUpperCase()}
              </Avatar>
            </IconButton>

            <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 200, p: 0 }}>
              <Box sx={{ p: 2, pb: 1.5 }}>
                <Typography variant="subtitle2" noWrap>
                  {context?.user?.name}
                </Typography>

                <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
                  {context?.user?.email}
                </Typography>
              </Box>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <Stack sx={{ p: 1 }}>
                {OPTIONS.map((option) => (
                  <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
                    {option.label}
                  </MenuItem>
                ))}
                <MenuItem onClick={handleOpenLayoutSettings}>{t('navigationPopover.layout')}</MenuItem>
              </Stack>

              <Divider sx={{ borderStyle: 'dashed' }} />

              <MenuItem
                onClick={handleLogout}
                sx={{ m: 1, fontWeight: 'fontWeightBold', color: 'error.main' }}
              >
                {t('navigationPopover.logout')}
              </MenuItem>
            </CustomPopover>
          </>
        )
      }
    </>
  );
}
