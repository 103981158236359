import { styled } from '@mui/system';
import {
    Paper,
    IconButton
} from '@mui/material';

export const CodeContainer = styled(Paper)(({ theme }: { theme: any }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1E1E1E' : '#F8F9FA',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
    position: 'relative',
    fontFamily: 'Consolas, Monaco, "Courier New", monospace',
    fontSize: '0.875rem',
    overflow: 'hidden',
}));

export const CodeContent = styled('pre')(({ theme }: { theme: any }) => ({
    margin: 0,
    padding: theme.spacing(1),
    overflowX: 'auto',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    color: theme.palette.mode === 'dark' ? '#E0E0E0' : '#24292E',
    '&::-webkit-scrollbar': {
        height: 8,
        width: 8,
    },
    '&::-webkit-scrollbar-thumb': {
        backgroundColor: theme.palette.mode === 'dark' ? '#424242' : '#C1C1C1',
        borderRadius: 4,
    },
    '&::-webkit-scrollbar-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2D2D2D' : '#F1F1F1',
    },
}));

export const CopyButton = styled(IconButton)(({ theme }: { theme: any }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
        backgroundColor: theme.palette.action.hover,
    },
    boxShadow: theme.shadows[2],
}));