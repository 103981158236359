import { Helmet } from 'react-helmet-async';

import CompleteApplyIntentView from 'src/sections/auth/apply-intent/complete-apply-intent-view';

export default function CompleteApplyIntentPage() {
  return (
    <>
      <Helmet>
        <title>JT: Complete Registration</title>
      </Helmet>

      <CompleteApplyIntentView />
    </>
  );
}
