import axios from 'axios';
import type { BaseQueryFn } from '@reduxjs/toolkit/query';
import type { AxiosError, AxiosRequestConfig } from 'axios';

import { paths } from 'src/routes/paths';

import { localStorageGetItem, localStorageSetItem } from 'src/utils/local-storage';

import { logOut } from 'src/auth/utils';
import { openFeatureAccessModal } from 'src/store/slices/billing/billingSlice';

import { BusinessErrorCodes } from 'src/types/business-errors';

// safe axios instance with no interceptors/guards/headers attached
export const safeAxiosInstance = axios.create({
  baseURL: import.meta.env.VITE_BACKEND_URL
});

const axiosInstance = axios.create();

axiosInstance.interceptors.response.use(
  (config) => config,
  (error) => {
    if (error.response?.status === 401) {
      logOut();
    }

    throw error;
  }
);

const axiosBaseQuery =
  (
    { baseUrl }: { baseUrl: string } = { baseUrl: '' }
  ): BaseQueryFn<
    {
      url: string;
      method?: AxiosRequestConfig['method'];
      data?: AxiosRequestConfig['data'];
      params?: AxiosRequestConfig['params'];
      headers?: AxiosRequestConfig['headers'];
    },
    unknown,
    unknown
  > =>
    async ({ url, method, data, params, headers }, api) => {
      const token = localStorageGetItem('access_token', undefined);

      try {
        const result = await axiosInstance({
          url: baseUrl + url,
          method,
          data,
          params,
          headers: {
            ...headers,
            ...(token ? { Authorization: `Bearer ${token}` } : {}),
          },
        });
        return { data: result.data };
      } catch (axiosError) {
        const err = axiosError as AxiosError;

        console.log('Network error occurred.');

        if (err.response?.status === 401) {
          console.log('Navigating to login page');

          // manage redirection
          localStorageSetItem('auth_return_to', window.location.pathname);

          window.location.href = paths.auth.login;
        }

        if (err.response?.status === 402) {
          const { data: responseData }: any = err.response;
          if (responseData?.feature_type) {
            api.dispatch(openFeatureAccessModal({
              featureType: responseData.feature_type
            }));
          }
        }

        if (err.response?.data) {
          if ((err.response.data as any).error_code === BusinessErrorCodes.InactiveOrganization) {
            window.location.href = paths.auth.register.verify;
          }

          if ((err.response.data as any).error_code === BusinessErrorCodes.AccountLocked) {
            logOut();
          }
        }

        return {
          error: {
            status: err.response?.status,
            data: err.response?.data || err.message,
          },
        };
      }
    };

export const getSearchParams = (data: { [key: string]: any }) => {
  const searchParams = new URLSearchParams();
  Object.keys(data).forEach((key) => searchParams.append(key, data[key]));

  return searchParams;
};

export default axiosBaseQuery;
