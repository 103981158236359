import { AuthContext } from "src/services/auth/auth.types";
import { useCheckAuthStatusQuery } from "src/services/auth/auth.service";

interface IAuthResult {
  isAuthenticated: boolean;
  isLoading: boolean;
  context: AuthContext | undefined;
  refreshAuthState: () => void;
}

export const useAuth = (): IAuthResult => {

  const { currentData: data, isError, isLoading, refetch } = useCheckAuthStatusQuery(undefined);

  return {
    refreshAuthState: refetch,
    isAuthenticated: data !== undefined,
    isLoading,
    context: data,
  };
};
