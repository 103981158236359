import { enqueueSnackbar } from 'notistack';

import { Box, styled } from '@mui/system';
import {
    Card,
    Stack,
    useTheme,
    TextField,
    CardHeader,
    Typography,
    CardContent,
    useMediaQuery,
    InputAdornment
} from '@mui/material';

import { useTranslate } from 'src/locales';
import { useAuthContext } from 'src/auth/hooks/useAuthContext';
import { useGetBoardByOrganizationQuery } from 'src/services/jobs/jobs.service';

import Iconify from 'src/components/iconify';
import { CopyButton, CodeContent, CodeContainer } from 'src/components/code/code-container';


const StyledTextField = styled(TextField)(({ theme }: { theme: any }) => ({
    '& .MuiOutlinedInput-root': {
        backgroundColor: theme.palette.background.paper,
    },
}));

export default function JobBoardCard() {
    const { t } = useTranslate();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const authContext = useAuthContext();

    const { currentData: jobBoard, isLoading: retrievingJobBoard } = useGetBoardByOrganizationQuery(
        {
            organizationId: authContext.organization.id,
        },
        {
            skip: !authContext.organization.id,
        }
    );

    const handleCopy = async (text: string) => {
        if (!navigator.clipboard || !text) return;
        navigator.clipboard.writeText(text);
        enqueueSnackbar(t('common.copied_to_clipboard'), { variant: 'success' });
    };

    if (retrievingJobBoard || !jobBoard) {
        return (
            <Box sx={{ width: '100%' }}>
                <Card>
                    <CardContent>
                        <Typography>{t('common.loading')}</Typography>
                    </CardContent>
                </Card>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Card
                sx={{
                    width: '100%',
                    position: 'relative',
                    '&:hover': {
                        boxShadow: theme.shadows[4],
                    },
                }}
            >
                <CardHeader
                    avatar={
                        <Iconify
                            icon="proicons:board"
                            sx={{
                                width: 40,
                                height: 40,
                                color: theme.palette.primary.main,
                            }}
                        />
                    }
                    title={
                        <Typography variant="h6" component="div">
                            {t('admin-organization.job-board.title')}
                        </Typography>
                    }
                />

                <CardContent>
                    <Stack spacing={3}>
                        {/* Embed Code Section */}
                        <Box>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('admin-organization.job-board.embed-code')}
                            </Typography>
                            <CodeContainer elevation={2}>
                                <CodeContent>{jobBoard.embed_code}</CodeContent>
                                <CopyButton
                                    size="small"
                                    onClick={() => handleCopy(jobBoard.embed_code)}
                                    title={t('common.copy')}
                                >
                                    <Iconify icon="material-symbols:content-copy" />
                                </CopyButton>
                            </CodeContainer>
                        </Box>

                        {/* Public Link Section */}
                        <Box>
                            <Typography variant="subtitle1" gutterBottom>
                                {t('admin-organization.job-board.public-link')}
                            </Typography>
                            <StyledTextField
                                fullWidth
                                value={jobBoard.link}
                                disabled
                                InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <CopyButton
                                                size="small"
                                                onClick={() => handleCopy(jobBoard.link)}
                                                title={t('common.copy')}
                                            >
                                                <Iconify icon="material-symbols:content-copy" />
                                            </CopyButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                        </Box>
                    </Stack>
                </CardContent>
            </Card>
        </Box>
    );
}